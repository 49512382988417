.slider-container {
    width: 100%;
    max-width: 1000px;
    margin: 2% auto;
    padding: 1px;
    border: 1px solid #ff4e4; /* Light gray border color */
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1); /* Lighter shadow */
    border-radius: 10px;
    background-color: #E0E0E0; /* Light gray background color */
    color: #2c2c2c; /* Dark text color */
    position: relative;
    overflow-y: hidden;
    transition: background-color 0.5s; /* Smooth background transition */
}

.slide-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    padding: 0 10px;
    box-sizing: border-box;
}

.nav-button {
    background-color: #D3D3D3; /* Slightly darker gray */
    color: #2c2c2c; /* Dark text color */
    border: 1px solid #adb5bd; /* Light gray border color */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s; /* Add transform transition */
}

.nav-button:hover {
    background-color: #C8C8C8; /* Even darker gray on hover */
    transform: scale(1.05); /* Slight growth effect on hover */
}

.nav-button:active {
    background-color: #BDBDBD; /* Much darker gray on active */
    transform: scale(0.95); /* Slight shrink effect on click */
}
