/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

/* App Container */
.App {
    display: flex;
    height: 100vh;
    background-color: #f0f2f5;
}

/* Sidebar Styles */
.sidebar {
    width: 250px;
    background: #283340; /* Removed gradient for consistent color */
    color: #ffffff;
    padding: 20px 0; 
}

/* Content Area */
.content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #39424e; /* Changed to bluish-gray */
    justify-content: center;
    align-items: center;
    padding: 40px; /* Added padding */
}

.chat-container {
    min-width: 90%; /* Adjusted width */
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #ffffff; /* White background for the chat container */
    border-radius: 15px; /* Rounded corners for chat container */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Box shadow for chat container */
}

/* Blocks */
.block {
    margin: 10px;
    padding: 15px; /* Increased padding */
    background-color: #ffffff;
    border-radius: 10px; /* Adjusted border-radius */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    color: #333333;
}

.block.system {
    background-color: #e1e5ea;
    color: #555555;
}

.block.user {
    background-color: #3b5a7d;
    color: white;
    text-align: right;
}

/* Input Container */
.input-container {
    position: fixed;
    bottom: 0;
    width: calc(100% - 250px);
    background: linear-gradient(45deg, #3b5a7d, #283340); /* Added gradient */
    padding: 12px 20px; /* Adjusted padding */
    display: flex;
    align-items: center;
    z-index: 1;
    border-top: 1px solid #ccc; /* Added border */
}

/* Input Field */
input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border-radius: 20px; /* Adjusted border-radius */
    border: 1px solid #ccc;
    margin-right: 10px;
}

/* Send Button */
button {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #3b5a7d;
    border: 1px solid #3b5a7d;
    cursor: pointer;
    border-radius: 20px; /* Adjusted border-radius */
    transition: background-color 0.3s ease;
}

/* button:hover {
    background-color: #3b5a7d;
    color: white;
} */

/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f0f2f5;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Style for the menu button */
.menu-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000; /* Ensure it's on top */
}

