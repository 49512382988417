/* Main quiz container */
.quiz-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Score Panel */
.score-panel {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.score-panel h2 {
  font-size: 1.5em;
  color: #004085;
  margin-bottom: 0;
}

/* Options container */
.options-container {
  display: flex;
  flex-direction: column; /* Stack options vertically */
  gap: 10px;
  margin-top: 10px;
}

/* Individual option label */
.option-label {
  display: flex;
  align-items: center;
  color: #333;
}

/* Custom radio buttons */
.option-label input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #007bff;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #dcdcdc;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.option-label input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #007bff;
  background-color: CanvasText;
}

.option-label input[type='radio']:checked::before {
  transform: scale(1);
}

/* Transition effect */
.option-label input[type='radio'] {
  transition: background-color 0.2s, border-color 0.2s;
}

/* Adjust Typography */
.MuiTypography-subtitle1 {
  font-size: 1.1rem;
}
