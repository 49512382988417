/* styles.css */
.centered-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .centered-content {
      width: 80%;
    }
  }
  


  
