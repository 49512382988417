:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #f4f6f9;
  --text-color: #34495e;
  --hover-color: #2980b9;
  --section-color-1: #ffffff;
  --section-color-2: #ecf0f1;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* Sticky Navigation at the Top */
.scrollable-nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 100000000;
  padding: 15px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  flex-wrap: wrap;
}

/* Container for Buttons */
.nav-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0 15px;
}

/* Buttons for Sections */
.scrollable-nav button {
  margin: 5px;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: var(--text-color);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  overflow: hidden;
}

/* Underline effect */
.scrollable-nav button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.scrollable-nav button:hover::after,
.scrollable-nav button.active::after {
  transform: scaleX(1);
}

.scrollable-nav button.active {
  font-weight: bold;
  color: var(--primary-color);
  background-color: rgba(52, 152, 219, 0.1); /* Light blue background */
}

.scrollable-nav button:hover {
  color: var(--primary-color);
  background-color: rgba(52, 152, 219, 0.05); /* Very light blue background */
}

.scrollable-nav button:active {
  transform: scale(0.95);
}

/* Remove border-radius for rectangular buttons */
.scrollable-nav button {
    border-radius: 0; /* Set to zero for sharp corners */
}


/* Main Content Area */
.main-content {
  padding: 20px 0;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

/* Section Styles */
.section {
  width: 100%;
  padding: 40px 20px;
  margin-bottom: 30px;
  background-color: var(--section-color-1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section:nth-child(odd) {
  background-color: var(--section-color-1);
}

.section:nth-child(even) {
  background-color: var(--section-color-2);
}

.section.active {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.section h2 {
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.section p {
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Button for Expanding Details */
.section button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.section button:hover {
  background-color: var(--hover-color);
}

/* Styles for GeoGebra section */
.geogebra-section {
  padding: 0;
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

.geogebra-section > div {
  width: 100%;
  height: auto;
}

/* Video container styles */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Image styles */
.section img {
  width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .scrollable-nav,
  .nav-buttons-container,
  .main-content,
  .section {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .geogebra-section,
  .video-container,
  .section img {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (max-width: 767px) {
  .scrollable-nav,
  .nav-buttons-container,
  .main-content,
  .section,
  .geogebra-section,
  .video-container,
  .section img {
    width: 100%;
  }
  
  .section {
    padding: 20px 15px;
  }
}