/* General styles for the app */
.app {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f5f5f5;
}

/* Styles for the video section */
.video-section {
  height: 300px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 50%;
}

/* Styles for the details section */
.details-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 50%;
}

.details-text {
  margin-left: 20px;
}

/* Styles for the enroll button */
.enroll-button {
  padding: 10px 20px;
  margin-top: 10px;
}

/* Styles for the cards container */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Styles for individual cards */
.card {
  width: calc(25% - 20px);
}

.card-caption {
  margin-bottom: 10px;
}

.card-description {
  margin-bottom: 10px;
}

.tags {
  margin-top: 10px;
}

.tag {
  margin-right: 5px;
  margin-top: 5px;
}

/* Styles for Material-UI tabs */
.MuiTabs-root {
  margin-bottom: 20px;
  justify-content: center;
}

.MuiTab-root {
  color: white;
  font-weight: bold;
}

.MuiTab-root.Mui-selected {
  background-color: #3f51b5;
  color: white;
}
