/* .mask { display: none }
 */

 .mask{   opacity: 0.2;}


  
  .modal {
    color: #ccc;
    background-color: #3d3d3d;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 800px;
    min-height: 100px;
    padding: 10px;
    position: absolute;
    z-index: 100000000000;
    /* top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);  */
    text-align: left; /* added this line */
  }

  .modal > p {
    /* styles for p elements that are children of .modal */
    color: red;
    font-size: 20px;
  }

  
  .close-button {
    background: transparent;
    border: 0;
    color: white;
    font-size: 18px;
    position: absolute; 
    right: 10px;
    top: 10px;
  }
  
  .close-button:hover {
    color: #ccc;
  }


  .modal-content {
    color: #ccc;
    padding: 0;
    /* top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);  */
    text-align: left; /* added this line */
  }

  .modal-content > p {
    /* styles for p elements that are children of .modal */
    color: red;
    font-size: 20px;
    text-align: left;
    padding: 0;

  }

  .marker {
    background-color: #ff5c5c;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
    height: 30px;
    position: absolute;
    width: 30px;
    cursor: 'pointer';
    display: 'flex';
    animation: blink 15s linear 1;
    align-items: "center",
  }
  


  @media (max-width: 768px) {
    .marker {
      width: 25px;
      height: 25px;
    }
  }


  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  


  .drag-handle {
    cursor: move; /* changes the cursor to a "move" icon when hovering over the handle */
    background-color: #f1f1f1; /* adds a background color to the handle */
    padding: 10px; /* adds padding to the handle */
    border-radius: 5px; /* adds rounded corners to the handle */
  }

  .buttons-container {
    display: flex;
    flex-wrap: wrap;
    max-width: '80%';
    padding: 10px;

  }
  
  .buttons-container button {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: left;
  }

  .topic-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .topic-button {
    margin: 12px;
    padding: 10px 20px;
    background-color: lightgray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .topic-button:hover {
    background-color: rgb(128, 128, 128);
  }
  
  .topic-buttons {
    display: flex;
    color: "white"
  }
  
  .topic-button {
    margin-right: 20px;
  }
  
  .arrows {
    display: flex;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .arrow {
    height: 10px;
    width: 10px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    transform: rotate(45deg);
    margin-right: 20px;
  }
  
  .arrow.active {
    border-color: red;
  }

  .selected-topic-button {
    background-color: #d7da2a;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ripple {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}


.text-element {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

@media (max-width: 576px) {
  .text-element {
    font-size: 20px;
  }
}



/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}