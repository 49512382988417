.quiz-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
    border: 1px solid #e5e5e5; 
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* background-color: #e1e5ea; */
    background-color: #fff4e4; /* Slightly darker than #ccd1d9 */
    justify-content: space-between; /* Adjust as needed to layout your content */
    max-width: 100%; /* Adjust the max-width as necessary */
    margin: auto;

}


.media-panel {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #d3d3d3; 
    border-radius: 5px;
    color: white;
    flex-wrap: wrap;
}


/* Regular Button Styles */
.quiz-slide button {
    padding: 10px 20px;
    margin: 5px 0;
    background-color: #e8e8e8; /* Light gray background */
    border: none;
    color: #2c2c2c; /* Dark text color */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}



/* Confirm Button Styles */
.quiz-slide .confirm-button {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #007BFF; /* Consistent blue background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: opacity 0.3s ease; /* Transition only affects the opacity */
}





.question-panel {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e8e8e8; 
    padding: 15px;
    border-radius: 8px;
    color: #2c2c2c; 
}

h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #2c2c2c;
}




p.wrong {
    color: #e74c3c;
}

p {
    margin-top: 20px;
    color: #555; 
}



.media-panel img,
.media-panel video {
    max-width: 100%; 
    max-height: 400px;
    object-fit: contain;
}

.feedback-container {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #b0b0b0; 
    border-radius: 5px;
    color: white;
}

.slider-container {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
}

.slider-container::-webkit-scrollbar {
    width: 10px;
}

.slider-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.slider-container::-webkit-scrollbar-track {
    background: #b0b0b0;
}

.slider-container {
    scrollbar-width: thin;
    scrollbar-color: #ccc #b0b0b0;
}

/* Feedback container base styles */
.feedback-container {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    transition: color 0.3s, border-color 0.3s;
    border: 2px solid transparent; /* add a border */
  }
  
  /* Styles for correct feedback */
  .feedback-container.correct {
    color: #2e7d32; /* a darker shade of green for better contrast */
    border-color: #b9f6ca; /* light pastel green border */
  }
  
  /* Styles for incorrect feedback */
  .feedback-container.incorrect {
    color: #c62828; /* a darker shade of red for better contrast */
    border-color: #ff8a80; /* light pastel red border */
  }
  

  /* Add styles for feedback content images and videos */
.feedback-content img,
.feedback-content video {
  max-width: 100%; /* Ensures the media does not exceed the container's width */
  max-height: 200px; /* You can adjust this value as needed */
  object-fit: contain; /* Keeps the aspect ratio and fits the content within the bounds */
  margin-bottom: 10px; /* Adds some space below the media */
}

.feedback-content {
  text-align: center; /* Centers the text and content */
}

.feedback-content p {
  color: #2c2c2c; /* Styling the text color */
  margin-bottom: 10px; /* Space below the text */
}

.feedback-content a {
  display: inline-block; /* Allows for margin and padding */
  margin-top: 10px; /* Space above the link */
  color: #1a0dab; /* Styling the link color, similar to default blue link color */
  text-decoration: underline; /* Underline to indicate it's a link */
}

