.markdown-with-video {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}
  
.markdown-content {
    flex: 1;
    max-width: 600px;
}

.video-content {
    flex: 1;
    max-width: 400px;
    height: auto;
}

/* Add styles for h1 and h2 within markdown-content */
.markdown-content h1,
.markdown-content h2 {
    color: rgb(224, 213, 213); /* Change this to whatever color you desire */
    font-weight: normal; /* If you don't want them bold */
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

/* Add styles for paragraph within markdown-content */
.markdown-content p {
    color: rgb(98, 92, 92); /* Your earlier mentioned color */
    font-family: 'Roboto', sans-serif; /* If you're using this font */
    line-height: 1.6;
}
