.click-to-enlarge {
    text-align: center;
    color: #555;
    margin-top: 8px;
    font-size: 0.9em;
    cursor: pointer;
  }
  
  /* Adjust image styling as needed */
  .feedback-content img {
    width: 100%; /* Adjust width as needed, or use max-width for larger images */
    height: auto;
    cursor: zoom-in; /* Indicates the image can be clicked to enlarge */
  }
  
  /* Optional: Add a hover effect for better user experience */
  .feedback-content img:hover {
    opacity: 0.9; /* Slight visual feedback on hover */
  }
  